import { useEffect, useState } from 'react'

import {
  GeneratePropertyMeRefreshToken,
  GeneratePropertyMeRefreshTokenVariables,
} from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import { Metadata, useMutation } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

export enum PmeResponseStatus {
  SUCCESS,
  FAIL,
}

export const GENERATE_PROPERTY_ME_REFRESH_TOKEN = gql`
  mutation GeneratePropertyMeRefreshToken(
    $input: PmeGenerateRefreshTokenInput!
  ) {
    pmeGenerateRefreshToken(input: $input) {
      token
    }
  }
`

const PropertyMeAppAuthPage = () => {
  const [showError, setShowError] = useState<boolean>(false)

  const { code } = useParams()

  const pmeBroadcast = new BroadcastChannel(
    'integrations_propertyme_authorisation',
  )

  const sendBroadcast = async (status) => {
    await pmeBroadcast.postMessage(status)
  }

  const [generatePropertyMeRefreshToken] = useMutation<
    GeneratePropertyMeRefreshToken,
    GeneratePropertyMeRefreshTokenVariables
  >(GENERATE_PROPERTY_ME_REFRESH_TOKEN, {
    onCompleted: async (data) => {
      if (data?.pmeGenerateRefreshToken?.token !== '') {
        await sendBroadcast(data.pmeGenerateRefreshToken.token)
        window.close()
      } else {
        setShowError(true)
        await sendBroadcast(PmeResponseStatus.FAIL)
      }
    },
    onError: async () => {
      setShowError(true)
      await sendBroadcast(PmeResponseStatus.FAIL)
    },
  })

  useEffect(() => {
    const getAndSendPropertyMeRefreshToken = async () => {
      const input = {
        variables: {
          input: {
            code,
          },
        },
      }

      await generatePropertyMeRefreshToken(input)
    }

    getAndSendPropertyMeRefreshToken()
  }, [])

  return (
    <>
      <Metadata
        title="PropertyMe Authentication"
        description="PropertyMe Authentication page"
      />

      <div className="min-h-screen grid place-items-center">
        {showError && (
          <p className="text-center">
            There was an error processing your authentication request.
            <br />
            Please close this tab and try again.
          </p>
        )}
        {!showError && <LoadingSpinner />}
      </div>
    </>
  )
}

export default PropertyMeAppAuthPage
